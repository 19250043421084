const DEV_SERVERS = [
    "localhost", 
    "127.0.0.1",
    "slbw-bookmy.project8.inscribecms.com",
    "4f19-144-130-102-97.au.ngrok.io"
]
if (DEV_SERVERS.includes(window.location.hostname)){
    window.bookmy = {
        // url:"http://bookmy.project8.inscribecms.com/",
        url:"https://bookmy-demo.beetleblack.com.au/",
        widget_url: 'http://localhost:3000/',
    }
}

export const BASE_API_URL = window.bookmy.url + "ajax.php";

export const SERVICE_MODES = {
    'hotel':'hotel',
    'timeslots':'restaurant',
    'takeaway':'restaurant'
}

export const DAYS = ['Sun', 'Mon', 'Tue','Wed','Thu','Fri', 'Sat'];

export const BOOKING_OBJECT_TYPES = {
    "Table":1,
    "Room":2,
    "Takeaway":3
}

export const DEFAULT_USER_META ={
    ap:'besc',
    os:2,
    av:'1.0.0',
    u: 0,
    d: 0,
    i:'web'
}

export const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/';

export const WAYO_PRIVACY_URL ="https://www.wayoapp.com.au/privacy-policy";
export const WAYO_SUPPORT_URL ="https://www.wayoapp.com.au/support";

export const DEFAULT_MAX_WIDTH = '600px';

