import React, { lazy, Suspense, useContext, useState, useEffect } from 'react';
import { UserContext } from "contexts/UserProvider";
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

const Snackbar = lazy(() => import('atoms/shared/Snackbar'));
const Loading = lazy(() => import('atoms/shared/Loading'));
export default function AppWrapper() {
	const [isShowLoading,setIsShowLoading] = useState(false);

	const { contextValue:{isLoading } } = useContext(UserContext);

	useEffect(()=>{
		setIsShowLoading(isLoading)
	}, [isLoading])

	return (
		<>
			<Router>
				<Suspense fallback={<Loading />}>
					<div style={{minHeight:'100vh'}}>
						{isShowLoading && <Loading/>}
						<Snackbar />
						<App /> 
					</div>
				</Suspense>
			</Router>
		</>
	);
}