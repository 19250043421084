import React, { lazy, useContext, useEffect } from 'react';
import { useRoutes, Navigate, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { UserContext } from "contexts/UserProvider";
import { DEFAULT_MAX_WIDTH } from "utils/constants";

const ResetPassword = lazy(()=> import('atoms/screens/ResetPassword'));
const Login = lazy(()=> import('atoms/screens/Login'));
const Register = lazy(()=> import('atoms/screens/Register'));
const Home = lazy(()=> import('atoms/screens/Home'));
const BookingConfirmed = lazy(()=> import('atoms/screens/BookingConfirmed'));
const YogaHome = lazy(()=> import('atoms/screens/YogaHome'));
const YogaBooking = lazy(()=> import('atoms/screens/YogaBooking'));
const Error404 = lazy(() => import('atoms/screens/Error404'));

function App() {
  const locaiton = useLocation();
  const { contextValue:{isLogin} } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    console.log("web rul", window.location.href)
      for (const entry of searchParams.entries()) {
        const [name, value] = entry;
        // params += `${name}=${value}&`;
        console.log("param", name, value)
      }

    
    if(!isLogin){
      // if(!['/login','/booking-details'].includes(location.pathname) ){
      //   localStorage.setItem('redirect',location.pathname);
        
      // }
      // let params = '?';
      // for (const entry of searchParams.entries()) {
      //   const [name, value] = entry;
      //   params += `${name}=${value}&`;
      // }
      // params = params.slice(0, -1);
      // navigate(`/login${params}`);
      // return;
    }
    else{
      if(location.pathname == '/login'){
        navigate('/home')
      }
      const redirect = localStorage.getItem('redirect');
      if(redirect){
        navigate(redirect);
        localStorage.removeItem('redirect');
      }
    }
  }, [isLogin]);

  useEffect(() => {
    // navigate('/register')
  }, [])
  


  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/home" replace={true} />
    },
    { path: '/reset-password', element: <ResetPassword /> },
    { path: '/login', element: <Login /> },
    { path: '/register', element: <Register /> },
    { path: '/home', element: <Home /> },
    { path: '/yoga-home', element: <YogaHome /> },
    { path: '/yoga-booking', element: <YogaBooking /> },
    { path: '/booking-confirmed', element: <BookingConfirmed /> },
    { path: '/404', element: <Error404 /> },
    { path: '*', element: <Navigate to="/404" replace={true} /> },
  ]);

  const defaultBgImage = require("./assets/images/bg-r2.jpg")
  return<div> 
  {/* <div style={{backgroundImage: !['/booking-details','/404'].includes(locaiton.pathname)? `url(${defaultBgImage})`: 'none'}}> */}
      <div class="mx-auto w-100" 
            style={{
              backgroundColor:!['/login'].includes(location.pathname)?'white':'transparent',
              maxWidth:!['/booking-details','/404'].includes(locaiton.pathname)
              ? DEFAULT_MAX_WIDTH : '100vw'}}>
      {routes}
    </div>
  </div>;
}

export default App;
